import axios from 'axios'
import { n2api } from './n2api';
import { useAuth } from '../Hooks/useAuth';

const useGoogleAdsAuth = () => {
    
    const { token } = useAuth();

    const getAccessToken = (authorization_code) => {
        const redirect_uri = process.env.REACT_APP_GOOGLE_ADS_REDIRECT_URI;
        return n2api.post(`/googleads/auth`, {
            code: authorization_code,
            redirect_uri: redirect_uri
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return {
                    success: false,
                    response: {},
                    error: error
                }
            });
    }
    
    const storeCredentials = (accessToken, refreshToken) => {
        const createCredentialDto = {
            accessToken: accessToken,
            refreshToken: refreshToken,
        };
        
        // Make a POST request to the n2api backend to store the access token
        return n2api.put(`/googleads/credentials`,  createCredentialDto ,{
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            console.log('Access token stored successfully');
            return true;
        })
        .catch((error) => {
            console.error('Error storing Access token:', error);
            return false;
        });
    }

    const deleteCredentials = () => {
        return n2api.delete(`/googleads/credentials`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            console.log('Access token deleted successfully');
            return true;
        })
        .catch((error) => {
            console.error('Error deleting Access token:', error);
            return false;
        });
    }
    return {
        getAccessToken,
        storeCredentials,
        deleteCredentials,
    }
}

export default useGoogleAdsAuth;