import React from "react";
import useGoogleAdsAuth from "../../Api/useGoogleAdsAuth";
import GoogleAdsLogo from "../PlatformLogos/GoogleAdsLogo";

export default function GoogleConnectButton({ connected }) {
  const google_redirect_url = process.env.REACT_APP_GOOGLE_ADS_REDIRECT_URI;
  const google_client_id = process.env.REACT_APP_GOOGLE_ADS_CLIENT_ID;
  const googleAdsAuth = useGoogleAdsAuth();
  const [isConnected, setIsConnected] = React.useState(connected);
  const [isDisconnecting, setIsDisconnecting] = React.useState(false);

  return (
    <div className="-ml-px ">
      {
        !isConnected ?
        <a
          href={`https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/adwords&access_type=offline&response_type=code&client_id=${google_client_id}&redirect_uri=${google_redirect_url}`}
        >
          <button
            className={`bg-green hover:bg-opacity-70 text-white0 font-semibold flex items-center py-2 px-9 rounded`}>
            <GoogleAdsLogo className="h-6 w-6 mr-2" alt="GoogleAds Icon"/>
            <p>Connect to Google Ads</p>
          </button>
        </a>
        :
        <button 
          onClick={() => {
            setIsDisconnecting(true);
            googleAdsAuth.deleteCredentials().then(
              async response => {
                setIsConnected(!response);
                setIsDisconnecting(false);
              }
            );
          }}
          className={`bg-green hover:bg-opacity-70 text-white0 flex items-center font-semibold py-2 px-9 rounded`}>
            <GoogleAdsLogo className={`h-6 w-6 mr-2 ${
              isDisconnecting? 'animate-spin' : ''
            }`} alt="GoogleAds Icon"/>
          <p>Disconnect from Google Ads</p>
        </button>
      }
    </div>
  );
}