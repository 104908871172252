import axios from 'axios'
import { n2api } from './n2api';
import { useAuth } from '../Hooks/useAuth';

const useGoogleAdsAdAccounts = () => {
    
    const { token } = useAuth();
    
    const updateAdAccounts = async () => {
        
        // Make a POST request to the n2api backend to store the access token
        return n2api.put(`/googleads/adaccounts`,{} ,{
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            if(response.status === 200){
                return true;
            }
        })
        .catch((error) => {
            console.error('Error fetching ad accounts:', error);
            return false;
        });
        
        
    }
    return {
        updateAdAccounts,
    }
}

export default useGoogleAdsAdAccounts;