import { PencilIcon } from '@heroicons/react/20/solid'
import React, {  } from "react";
import PlatformConnectButtonFactory from '../PlatformConnectButtons/PlatformConnectButtonFactory';
import PlatformLogosFactory from '../PlatformLogos/PlatformLogosFactory';

// const items = [
//   {
//     active: true,
//     created_at: "2023-04-18T14:31:06.638407+00:00",
//     cronPattern: "* * * * *",
//     id: 6,
//     name: "Test Collector mit Queue",
//     platform: "Testplatform"
//   }
// ]




export default function PlatformsGridList({ items, baseUrl }) {


  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 2xl:grid-cols-3">
      {items && items.length > 0 ? items.map((item) => (
        <li key={item.name} className="col-span-1 divide-y divide-black1 rounded-lg bg-gray2 shadow">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-white0">{item.name}</h3>
                {/* <span className={`inline-flex flex-shrink-0 items-center rounded-full ${ item.active ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"} px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ring-green-600/20`}>
                  {item.active ? 'Active' : 'Inactive'}
                </span> */}
              </div>
              <p className="mt-1 truncate text-sm text-white1">{item.description}</p>
            </div>
            {/* <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={`https://api.dicebear.com/6.x/initials/svg?seed=${item.name}`} alt="" /> */}
            {
              PlatformLogosFactory.create(item.name)
            }
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-black1">
              <div className="flex w-0 flex-1 ">
                <a
                  href={`${baseUrl}/${item.name}`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-white0"
                >
                  <PencilIcon className="h-5 w-5 text-white0" aria-hidden="true" />
                  Edit
                </a>
              </div>
              <div className="items-center p-2">
              {
                PlatformConnectButtonFactory.create(item.name, item.connected)
              }
              </div>
            </div>
          </div>
        </li>
      ))
        :
        // display an empty list placeholder
        <li className="col-span-1 divide-y divide-black1 rounded-lg bg-gray2">
          <div className="flex w-full items-center bg-gray2 justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-white0">No Platforms found</h3>
              </div>
            </div>
          </div>
        </li>
      }

{/*       <div className="-ml-px ">
        <a
          href={`https://www.pinterest.com/oauth/?client_id=${pinterest_client_id}&redirect_uri=${pinterest_redirect_uri}&response_type=code&scope=boards:read,pins:read,boards:read_secret,ads:read,ads:write,catalogs:read`}
          className="relative inline w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
        >
          <button
            className={`bg-green hover:bg-opacity-70 text-white0 font-semibold py-2 px-9 rounded-full`}>
            <p>Connect to Pinterest</p>
          </button>
        </a>
      </div> */}

    </ul>
  )
}
