import React from 'react';
import CampaignsTable from '../../Components/CampaignsTable/Index';
import useCampaigns from '../../Api/useCampaigns';
import { useReporting } from '../../Api/useReporting';
import { useAuth } from '../../Hooks/useAuth';
import usePlatforms from '../../Api/usePlatforms';

const Campaigns = () => {

  const { getRunningCampaigns } = useCampaigns();
  const { getBasicReporting } = useReporting();
  const platforms = usePlatforms();
  const [campaigns, setCampaigns] = React.useState([]);
  const [activeAgentCampaigns, setActiveAgentCampaigns] = React.useState([]);
  const [pinterestCampaigns, setPinterestCampaigns] = React.useState([]);
  const [spotifyCampaigns, setSpotifyCampaigns] = React.useState([]);
  const [facebookCampaigns, setFacebookCampaigns] = React.useState([])
  const [facebookReporting, setFacebookReporting] = React.useState([]);
  const [googleCampaigns, setGoogleCampaigns] = React.useState([]);
  const { token } = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function getCampaigns() {
      setIsLoading(true);
      platforms.getConnectedPlatforms(token).then(async connectedPlatforms => {
        return connectedPlatforms.map(async platform => 
          await getRunningCampaigns(platform, token).then(campaigns => {
            campaigns.forEach(campaign => campaign.platform = platform);
            return campaigns;
          })
        )
      })
      .then(async (campaigns) => {
        await Promise.all(campaigns).then(c => setCampaigns(c.flat()))
        console.log(campaigns)
        setIsLoading(false);
      })
    }

    getCampaigns();
  }, []);

  React.useEffect(() => {
    const campaigns = [...activeAgentCampaigns, ...pinterestCampaigns, ...spotifyCampaigns, ...facebookCampaigns, ...googleCampaigns];
    setCampaigns(campaigns);
  }, [activeAgentCampaigns, pinterestCampaigns, spotifyCampaigns, facebookCampaigns, googleCampaigns]);


  return (
    <>
      <CampaignsTable isLoading={isLoading} campaigns={campaigns} />
    </>
  );
}

export default Campaigns;